import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NotAuthenticated from "../components/MyPage/NotAuthenticated"
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"

/**
 * This component is for the error page that the user gets if userProfile api fails.
 *
 * @version 1.0.1
 * @visibleName Error
 * @author [Dylan Lesperance]
 */

const ErrorPage = () => {
  const isAuthenticated = useIsAuthenticated()

  console.log(isAuthenticated)

  return (
    <div className="bg-gray px-8 py-8 pb-16 lg:px-32 lg:pb-32 lg:justify-center lg:items-center">
      <Seo title="Error page" />
      <h1 className="font-sans text-blue text-xl">Det har skjedd en feil</h1>
      <p className="font-sans">Prøv igjen ved å trykke på knappen under.</p>
      <Link to="/" className="font-sans text-blue hover:text-orange-dark">
        Gå tilbake til forsiden
      </Link>
    </div>
  )
}

export default ErrorPage
